body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active-side-menu {
  background-color: #ffcc00;
  /* border-radius: 10%; */
  /* padding: '20%'; */
}

.outlined-button {
  border: 1px solid #3f51b5; /* Border color */
  border-radius: 4px; /* Border radius */
  padding: 8px; /* Adjust padding as needed */
}

.outlined-button:hover {
  border-color: #303f9f; /* Change border color on hover */
}

.custom-btn {
  background-color: #ffcc00;
  color: "black";
  font-weight: "bold";
  text-transform: "none";
}

.btn-delete {
  width: 50px;
  height: 50px;
  background-color: red;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
